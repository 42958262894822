import Button from 'components/dist/atoms/Button';
import Checkbox from 'components/dist/atoms/Checkbox';
import { CheckboxProps } from 'components/dist/atoms/Checkbox/Checkbox.types';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Label from 'components/dist/atoms/Label';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import ActionAlertDialog from 'components/dist/molecules/ActionAlertDialog';
import FileIcon, { getFileNameExtension } from 'components/dist/molecules/FileIcon';
import LoadingBox from 'components/dist/molecules/LoadingBox';
import RenameAlertDialog from 'components/dist/molecules/RenameAlertDialog';
import { useRouter } from 'next/router';
import { trigger } from 'polyrhythm';
import { createContext, FC, useCallback, useContext, useMemo, useReducer } from 'react';
import { toast } from 'react-toastify';
import { AppUserDTO2, Document, DocumentAnswerV2Dto, ElementModifierType, KnowledgeBase, LoanRoleDto, LoanViewType, PackageInfoSharingResponseDto, Role, SharePermissionType, ShoeboxItemResponseDto, StorageType, TaskViewType } from 'src/backend';
import { UndoToast } from 'src/components/dashboard/dashboard-sidebar-shoebox/UndoToast';
import { CopyMoveToDialog } from 'src/components/form-elements/copy-move-to-dialog';
import { NewFolderDialog } from 'src/components/form-elements/new-folder-dialog';
import { LOAN_TABS } from 'src/components/loans/tabs/loan-tabs-nav';
import { AddMicrosoftAccountDialog } from 'src/components/sharepoint/add-microsoft-account-dialog';
import { FormElementStatusSetting } from 'src/components/v2/form-elements/form-element-status-icon';
import { QUERY_PARAM_FORM_ELEMENT_ID, QUERY_PARAM_FORM_ELEMENT_IDS } from 'src/constants/form-element';
import { FILE_SUBMIT_SUCCESS_TOAST_ID, FILE_UPLOAD_SUCCESS_TOAST_ID } from 'src/constants/toast';
import { QUERY_PARAM_TASK_VIEW } from 'src/constants/url';
import { useDocumentFileDownloadUrl } from 'src/hooks/document/use-document-file-download-url';
import { useUserLoanViewType } from 'src/hooks/loans/use-user-loan-view-type';
import { useGetLicenseKeysQuery } from 'src/services/appApi';
import { useLazyGetDocumentWithDownloadUrlQuery, useLazyGetDocumentWithEditDownloadUrlQuery, useUpdateDocumentMutation } from 'src/services/documentApi';
import { useDeleteShoeBoxItemMutation } from 'src/services/lenderShoeBoxApi';
import { loanApi } from 'src/services/loanApi';
import { packageApi, useAddAnswerToElementMutation, useCopyElementsMutation, useCreateElementsMutation, useCreateSharedInfoElementMutation, useDeleteAnswerFromElementMutation, useDeleteElementsMutation, useDeleteSharedInfoElementMutation, useGenerateElementsZipMutation, useLazyGetLoanElementsQuery, useUpdateElementsMutation } from 'src/services/packageApi';
import { taskApi } from 'src/services/taskApi';
import { useGetLoggedInUserQuery } from 'src/services/userApi';
import { uploadDocument } from 'src/slices/documents';
import { setOptimisticElementFileUploads } from 'src/slices/form-element';
import { getAllLoanUsersTasks } from 'src/slices/task';
import { useDispatch } from 'src/store';
import { FormElementV2ResponseDtoExtended } from 'src/types/formelement';
import { downloadFile } from 'src/utils/download-file';
import { downloadFileWithExtension } from 'src/utils/download-file-with-extension';
import { getSelectedElementsDetails } from 'src/utils/form-element/get-selected-elements-details';
import { isFormElementLocked } from 'src/utils/form-element/is-form-element-locked';
import { mergeFiles } from 'src/utils/merge-files';
import { pluralize } from 'src/utils/pluralize';
import { getFileFromUrl } from 'src/utils/url/get-file-from-url';
import { isRoleABorrower } from 'src/utils/user/is-role-a-borrower';
import { isRoleALead } from 'src/utils/user/is-role-a-lead';
import { isRoleAManager } from 'src/utils/user/is-role-a-manager';
import { useCopyToClipboard } from 'usehooks-ts';

import { FormElementContextReducerInitialState, UploadFormElementContextReducer } from './upload-form-element-context.reducer';

interface OnCopyMoveBase {
    operation: 'COPY' | 'MOVE';
    loanId: string;
}

interface OnCopyMoveElements extends OnCopyMoveBase {
    type: "ELEMENTS";
    elements: FormElementV2ResponseDtoExtended[]
}

interface OnCopyMoveShoeboxItems extends OnCopyMoveBase {
    type: "SHOEBOX_ITEMS";
    elements: ShoeboxItemResponseDto[]
}

type OnCopyMove = OnCopyMoveElements | OnCopyMoveShoeboxItems;

interface UploadFormElementContextValue {
    onConsolidatedViewChange: (data: FormElementContextReducerInitialState['consolidatedView']) => void;
    onUnshare: (args: { users: AppUserDTO2[], elements: FormElementV2ResponseDtoExtended[] }) => Promise<void>;
    onShare: (args: { users: AppUserDTO2[], elements: FormElementV2ResponseDtoExtended[], loanRoles: Pick<LoanRoleDto, 'role' | 'user'>[] }) => Promise<void>;
    onStageElements: (operation: 'DELETE' | 'RENAME' | null, data: FormElementV2ResponseDtoExtended[]) => void;
    onDropFiles: (args: DropFilesArgs) => Promise<void>;
    onDownload: (elements: FormElementV2ResponseDtoExtended[]) => Promise<void>;
    onSubmitElements: (elements: Pick<FormElementV2ResponseDtoExtended, 'id' | 'loanId' | 'title'>[]) => Promise<void>;
    onRemoveAnswer: (elements: FormElementV2ResponseDtoExtended[]) => Promise<void>;
    onDropElements: (element: FormElementV2ResponseDtoExtended, files: { title: string, loanId: string, document: DocumentAnswerV2Dto, id: string, type: 'FORM_ELEMENT' | 'SHOEBOX_ITEM' }[]) => Promise<void>;
    onCopyMove: (args: OnCopyMove) => void;
    onCopyElementLink: (id: string) => void;
    consolidatedView: FormElementContextReducerInitialState['consolidatedView'];
    onEditOneDriveFile: (element: FormElementV2ResponseDtoExtended, platform: "WEB" | "DESKTOP" | "WEB_ANONYMOUS") => Promise<Document>;
    isEditOneDriveFile: boolean;
}
const UN_MERGABLE_EXTENSIONS = ['xls', 'xlsx', 'xlsm'];

interface UploadFormElementContextProviderProps extends React.PropsWithChildren<{}> { }

interface DropFilesArgs {
    targetElement: FormElementV2ResponseDtoExtended;
    droppedFiles: File[];
}


const initialValues: UploadFormElementContextValue = {
    onConsolidatedViewChange: () => void 0,
    onUnshare: () => void 0,
    onShare: () => void 0,
    onStageElements: () => void 0,
    onDropFiles: () => Promise.resolve(),
    onCopyMove: () => void 0,
    onSubmitElements: () => Promise.resolve(),
    onDownload: () => Promise.resolve(),
    onRemoveAnswer: () => Promise.resolve(),
    onDropElements: () => Promise.resolve(),
    onCopyElementLink: () => Promise.resolve(),
    onEditOneDriveFile: () => void 0,
    consolidatedView: {
        dropType: "DEFAULT",
        storageType: 'FOLDER',
        formElement: null
    },
    isEditOneDriveFile: false
};

const UploadFormElementContext = createContext<UploadFormElementContextValue>(initialValues);

export const UploadFormElementContextProvider: FC<UploadFormElementContextProviderProps> = (props) => {
    const [stagedElements, dispatchStagedElements] = useReducer(UploadFormElementContextReducer.reducer, UploadFormElementContextReducer.initialState);
    const [getDownloadUrl] = useLazyGetDocumentWithDownloadUrlQuery();
    const [addAnswerToElement] = useAddAnswerToElementMutation();
    const [deleteAnswerFromElement] = useDeleteAnswerFromElementMutation();
    const { loanViewType } = useUserLoanViewType();
    const { data: licenseKeysData } = useGetLicenseKeysQuery();
    const [createElements] = useCreateElementsMutation();
    const [generateElementsZip] = useGenerateElementsZipMutation();
    const [updateElements] = useUpdateElementsMutation();
    const [getLoanElements] = useLazyGetLoanElementsQuery();
    const { getDocumentFileDownloadUrl } = useDocumentFileDownloadUrl();
    const [createSharedInfoElement] = useCreateSharedInfoElementMutation();
    const [deleteSharedInfoElement] = useDeleteSharedInfoElementMutation();
    const [deleteShoeboxItem] = useDeleteShoeBoxItemMutation();
    const [copyElements] = useCopyElementsMutation();
    const [, copyToClipBoard] = useCopyToClipboard();
    const [getDocumentWithEditDownloadUrl] = useLazyGetDocumentWithEditDownloadUrlQuery();
    const [updateDocument] = useUpdateDocumentMutation();


    const [deleteElements] = useDeleteElementsMutation();
    const { data: loggedInUserData } = useGetLoggedInUserQuery();
    const dispatch = useDispatch();
    const router = useRouter();

    const handleReplaceUndo = useCallback(async (items: FormElementContextReducerInitialState['undoUploadElements']) => {
        // if we have a document id we need to set it as the answer
        const promises = items.map(async ({ element, documentId }) => {
            if (documentId) {
                return addAnswerToElement({
                    elementId: element.id,
                    documentId,
                    submit: false,
                    answerId: null,
                    isMerged: false
                })
            } else {
                dispatch(setOptimisticElementFileUploads({
                    elementId: element.id,
                    fileName: null
                }))
                // otherwise we need to remove the answer
                return deleteAnswerFromElement({
                    elementId: element.id,
                    answerId: null,
                    documentId: null,
                    submit: null,
                    isMerged: false
                })
            }
        });
        await Promise.all(promises)
        const uniqueLoanIds = items.map(item => item.element.loanId).filter((value, index, self) => self.indexOf(value) === index)

        toast.success(`Undo successful`, {
            autoClose: 50000
        })
        loanApi.util.invalidateTags([{ type: "BasicLoanDto", id: "LIST" }])
        uniqueLoanIds.forEach(loanId => {
            taskApi.util.invalidateTags([{ type: 'ConsolidatedTasksDto', id: loanId }])
        })
        dispatchStagedElements({
            type: 'SET_UNDO_UPLOAD_ELEMENTS',
            payload: []
        })
    }, [addAnswerToElement, deleteAnswerFromElement, dispatch]);

    const handleUndoSubmit = useCallback(async (elements: Pick<FormElementV2ResponseDtoExtended, 'id' | 'loanId' | 'title'>[], callBack: () => void) => {
        try {
            await updateElements({
                multiSelect: false,
                elements: elements.map((element) => ({
                    id: element.id,
                    loanId: element.loanId,
                    inProgress: true
                }))
            }).unwrap();
            callBack?.();
            dispatchStagedElements({
                type: 'SET_UNDO_SUBMIT_ELEMENTS',
                payload: []
            })
        } catch (error) {
            toast.error(error.message);
        }
    }, [updateElements])

    const resetUndoUploadElements = useCallback(() => {
        dispatchStagedElements({
            type: 'SET_UNDO_UPLOAD_ELEMENTS',
            payload: []
        })
    }, [])

    const resetUndoSubmitElements = useCallback(() => {
        dispatchStagedElements({
            type: 'SET_UNDO_SUBMIT_ELEMENTS',
            payload: []
        })
    }, [])

    const onSubmitElements = useCallback(async (elements: Pick<FormElementV2ResponseDtoExtended, 'id' | 'loanId' | 'title'>[]) => {
        const viewType = router.query[QUERY_PARAM_TASK_VIEW] as TaskViewType;
        try {
            const combinedElements = [...stagedElements.undoSubmitElements, ...elements];
            dispatchStagedElements({
                type: 'SET_UNDO_SUBMIT_ELEMENTS',
                payload: combinedElements
            })
            await updateElements({
                multiSelect: false,
                elements: elements.map((element) => ({
                    id: element.id,
                    loanId: element.loanId,
                    submit: true
                }))
            }).unwrap();
            let toastMessage = `${combinedElements.length} items`;
            if (combinedElements.length === 1) {
                toastMessage = `${combinedElements[0].title} item`;
            }
            if (toast.isActive(FILE_SUBMIT_SUCCESS_TOAST_ID)) {
                toast.update(FILE_SUBMIT_SUCCESS_TOAST_ID, {
                    render: <UndoToast
                        onUndo={() => handleUndoSubmit(combinedElements, () => {
                            ['FILL_SIGN', 'UPLOAD'].includes(viewType) && router.push({
                                query: {
                                    ...router.query,
                                    [QUERY_PARAM_TASK_VIEW]: viewType
                                }
                            })
                        })}
                        message={`${toastMessage} submitted successfully`}
                    />,
                    onClose: resetUndoSubmitElements
                });
            } else {
                toast.success(<UndoToast
                    onUndo={() => handleUndoSubmit(combinedElements, () => {
                        ['FILL_SIGN', 'UPLOAD'].includes(viewType) && router.push({
                            query: {
                                ...router.query,
                                [QUERY_PARAM_TASK_VIEW]: viewType
                            }
                        })
                    })}
                    message={`${toastMessage} submitted successfully`}
                />, {
                    toastId: FILE_SUBMIT_SUCCESS_TOAST_ID,
                    onClose: resetUndoSubmitElements
                });
            }

        } catch (error) {
            toast.error(error.message);
        }
    }, [handleUndoSubmit, resetUndoSubmitElements, router, stagedElements.undoSubmitElements, updateElements]);


    const afterCopyMoveCleanup = useCallback(async (element: FormElementV2ResponseDtoExtended, elementsToHighlight: FormElementV2ResponseDtoExtended[]) => {
        const { operation, data, type } = stagedElements.stagedForCopyMove;
        try {

            // if operation was copy or move and we have source elements
            // and user loan view type is not simple we need to navigate to the target folder
            // we need to navigate to the target folder
            if ([`COPY`, `MOVE`].includes(operation) && data.length > 0 && loanViewType !== 'CONSOLIDATED_LENDER') {
                await router.push({
                    pathname: router.pathname,
                    query: {
                        ...router.query,
                        [QUERY_PARAM_FORM_ELEMENT_ID]: element.storageType === "FILE"
                            ? element.parentId :
                            element.id,
                        [QUERY_PARAM_FORM_ELEMENT_IDS]: elementsToHighlight.map(element => element.id)
                    },
                }, undefined, {
                    shallow: true
                })
                trigger('/elements/copy-move/highlight', elementsToHighlight)
            }
            // if operation was move 
            // and we moved to a file
            // we need to delete the moved elements
            // if we moved to a folder, then it's okay, because we only update the parentId
            if (operation === 'MOVE' && type === "ELEMENTS" && data.length > 0 && element.storageType === 'FILE') {
                await deleteElements({
                    elements: data.map(element => ({
                        id: element.id,
                        loanId: element.loanId,
                        storageType: element.storageType
                    })),
                    multiSelect: false
                });
            }
            // else if operation was move and type is shoebox item we need to delete shoebox items
            else if (operation === 'MOVE' && type === "SHOEBOX_ITEMS" && data.length > 0) {
                await Promise.all(data.map(item => deleteShoeboxItem({
                    id: item.id,
                })));
                dispatch(taskApi.util.invalidateTags([{ type: 'ConsolidatedTasksDto', id: element.loanId }]))
                dispatch(packageApi.util.invalidateTags([{ type: 'FormElementsV2ResponseDto', id: 'LIST' }]))
                dispatch(getAllLoanUsersTasks(element.loanId))
            }
        } catch (error) {
            console.error(error)
        } finally {
            // clear the staged elements
            dispatchStagedElements({
                type: 'SET_STAGED_FOR_COPY_MOVE',
                payload: {
                    type: null,
                    operation: null,
                    data: [],
                    loanId: null
                }
            })
            dispatchStagedElements({
                type: 'SET_OPERATION_IN_PROGRESS',
                payload: false
            })
        }

    }, [deleteElements, deleteShoeboxItem, dispatch, loanViewType, router, stagedElements.stagedForCopyMove]);

    const onDropFiles = useCallback(async (args: DropFilesArgs) => {
        dispatchStagedElements({
            type: 'SET_OPERATION_IN_PROGRESS',
            payload: true
        })
        const isLocked = isFormElementLocked(args.targetElement, loggedInUserData.viewType === "PRINCIPAL");
        // scenario 1 if source element is a folder, we need to upload the files to the folder
        // TODO
        // scenario 2 form element is a file and does not have an answer
        // we need to upload the file to a document then answer the question with the document
        if (isLocked) {
            dispatchStagedElements({
                type: 'SET_STAGED_FOR_ACTION',
                payload: {
                    action: 'LOCKED_WARNING',
                    element: args.targetElement
                }
            })
        } else if (args.targetElement.storageType === 'FILE' && !args.targetElement.answer) {
            dispatch(setOptimisticElementFileUploads({
                elementId: args.targetElement.id,
                fileName: args.droppedFiles[0].name
            }))
            // if we are dropping only one file
            let isMerged = false;
            let finaleFile: File = args.droppedFiles[0];
            // else if we are dropping multiple files
            // we need to merge the files first
            // then upload the merged file
            const undoElements = [
                ...stagedElements.undoUploadElements,
                { element: args.targetElement, documentId: null, droppedFiles: args.droppedFiles }
            ]
            dispatchStagedElements({
                type: 'SET_UNDO_UPLOAD_ELEMENTS',
                payload: undoElements
            })
            if (args.droppedFiles.length > 1) {
                isMerged = true;
                dispatch(setOptimisticElementFileUploads({
                    elementId: args.targetElement.id,
                    fileName: 'merged.pdf'
                }))
                finaleFile = await mergeFiles(args.droppedFiles, licenseKeysData?.pdftronKey);
            }
            // upload the file to a document
            // then answer the question with the document
            const documentId = await dispatch(uploadDocument({
                formElement: args.targetElement,
                file: finaleFile,
                loanId: args.targetElement.loanId,
                isMerged
            }));
            await addAnswerToElement({
                elementId: args.targetElement.id,
                documentId,
                submit: false,
                answerId: null,
                isMerged
            })
            // if a copy move operation is in progress
            // we need to display toast for the operation

            if (["COPY", "MOVE"].includes(stagedElements.stagedForCopyMove.operation)) {
                toast.success(`${args.droppedFiles.length} item${args.droppedFiles.length === 1 ? "" : "s"} ${stagedElements.stagedForCopyMove.operation === "COPY" ? "copied" : "moved"} to ${args.targetElement.title} successfully`)
            } else {
                const totalDropFiles = undoElements.reduce((acc, item) => acc + item.droppedFiles.length, 0)
                let toastMessage = ``
                if (totalDropFiles === 1) {
                    toastMessage = `${totalDropFiles} item${totalDropFiles === 1 ? "" : "s"} uploaded to ${args.targetElement.title} successfully`
                } else {
                    toastMessage = `${totalDropFiles} item${totalDropFiles === 1 ? "" : "s"} uploaded successfully`
                }
                if (toast.isActive(FILE_UPLOAD_SUCCESS_TOAST_ID)) {
                    toast.update(FILE_UPLOAD_SUCCESS_TOAST_ID, {
                        render: <UndoToast
                            onUndo={async () => handleReplaceUndo(undoElements)}
                            message={toastMessage} />,
                        onClose: resetUndoUploadElements,
                        className: 'mb-16'
                    })
                } else {
                    toast.success(<UndoToast
                        onUndo={async () => handleReplaceUndo(undoElements)}
                        message={toastMessage} />, {
                        toastId: FILE_UPLOAD_SUCCESS_TOAST_ID,
                        onClose: resetUndoUploadElements,
                        className: 'mb-16'
                    })
                }
            }
            afterCopyMoveCleanup(args.targetElement, [args.targetElement])
        } else if (args.targetElement.storageType === 'FILE' && args.targetElement.answer) {
            // scenario 3 form element is a file and has an answer
            // we need to show merge dialog
            dispatchStagedElements({
                type: 'SET_STAGED_FOR_MERGE',
                payload: {
                    target: {
                        type: 'ELEMENT',
                        element: args.targetElement
                    },
                    elements: [],
                    files: args.droppedFiles,
                    checkedFiles: args.droppedFiles,
                    loading: false,
                    hasFilesNeedsConvertToPdf: args.droppedFiles.some(file => getFileNameExtension(file.name) !== 'pdf')
                }
            })
        } else if (args.targetElement.storageType === 'FOLDER' && args.targetElement.knowledgeBase !== 'VIRTUAL_FOLDER') {
            // scenario 4 form element is a folder
            // we need to create elements for each file
            // then upload the files to the elements
            const promises = args.droppedFiles.map(async (file) => {
                const { list: [element] } = await createElements({
                    elements: [{
                        loanId: args.targetElement.loanId,
                        title: file.name,
                        storageType: "FILE",
                        parentId: args.targetElement.id,
                    }],
                    multiSelect: true
                }).unwrap();
                const documentId = await dispatch(uploadDocument({
                    formElement: element,
                    file,
                    loanId: args.targetElement.loanId,
                    isMerged: false
                }));
                await addAnswerToElement({
                    elementId: element.id,
                    documentId,
                    submit: false,
                    answerId: null,
                    isMerged: false
                })
            });
            await Promise.all(promises);
        } else if (args.targetElement.storageType === 'FOLDER' && args.targetElement.knowledgeBase === "VIRTUAL_FOLDER") {
            // scenario 5 form element is a virtual folder
            // we need to display the docs folder dialog
            dispatchStagedElements({
                type: 'SET_CONSOLIDATED_VIEW',
                payload: {
                    items: args.droppedFiles,
                    dropType: "FILE",
                    storageType: 'FOLDER',
                    formElement: args.targetElement
                }
            });
        }
        loanApi.util.invalidateTags([{ type: "BasicLoanDto", id: "LIST" }])
        taskApi.util.invalidateTags([{ type: 'ConsolidatedTasksDto', id: args.targetElement.loanId }])
    }, [addAnswerToElement, afterCopyMoveCleanup, createElements, dispatch, handleReplaceUndo, licenseKeysData?.pdftronKey, loggedInUserData.viewType, resetUndoUploadElements, stagedElements.stagedForCopyMove.operation, stagedElements.undoUploadElements]);

    const onDropElements = useCallback(async (targetElement: FormElementV2ResponseDtoExtended, droppedElements: { title: string, document: DocumentAnswerV2Dto, loanId: string, id: string, type: "FORM_ELEMENT" | "SHOEBOX_ITEM" }[]) => {

        // scenario 1 if source element is a folder
        // and folder is virtual
        // we need to display the docs folder dialog
        if (targetElement.storageType === 'FOLDER' && targetElement.knowledgeBase === 'VIRTUAL_FOLDER') {
            const formElements = droppedElements.filter(element => element.type === "FORM_ELEMENT")
            const shoeboxItems = droppedElements.filter(element => element.type === "SHOEBOX_ITEM")
            if (formElements.length > 0) {
                dispatchStagedElements({
                    type: 'SET_CONSOLIDATED_VIEW',
                    payload: {
                        items: formElements,
                        dropType: "FORM_ELEMENT" as const,
                        storageType: 'FOLDER',
                        formElement: targetElement
                    }
                });
            } else if (shoeboxItems.length > 0) {
                dispatchStagedElements({
                    type: 'SET_CONSOLIDATED_VIEW',
                    payload: {
                        items: shoeboxItems,
                        dropType: "SHOE_BOX_ITEM",
                        storageType: 'FOLDER',
                        formElement: targetElement
                    }
                });
            }
        } else if (targetElement.storageType === 'FOLDER') {
            dispatchStagedElements({
                type: 'SET_IS_LOADING_DIALOG_OPEN',
                payload: true
            })
            // else if folder is not virtual
            const formElements = droppedElements.filter(element => element.type === "FORM_ELEMENT")
            const shoeboxItems = droppedElements.filter(element => element.type === "SHOEBOX_ITEM")
            if (formElements.length > 0) {
                // set parentId of dropped elements to the target element id
                await updateElements({
                    multiSelect: true,
                    elements: droppedElements.map(element => ({
                        id: element.id,
                        loanId: element.loanId,
                        parentId: targetElement.id
                    }))
                }).unwrap();
            }
            if (shoeboxItems.length > 0) {
                // create elements for each shoebox item
                // and answer it with documentId
                await createElements({
                    elements: shoeboxItems.map(item => ({
                        loanId: targetElement.loanId,
                        title: item.title,
                        storageType: 'FILE',
                        parentId: targetElement.id,
                        documentId: item.document.id
                    })),
                    multiSelect: true
                }).unwrap();
                // delete the shoebox items
                await Promise.all(shoeboxItems.map(item => deleteShoeboxItem({
                    id: item.id,
                })));
                dispatch(taskApi.util.invalidateTags([{ type: 'ConsolidatedTasksDto', id: targetElement.loanId }]))
                dispatch(packageApi.util.invalidateTags([{ type: 'FormElementsV2ResponseDto', id: 'LIST' }]))
                dispatch(getAllLoanUsersTasks(targetElement.loanId))
            }
            dispatchStagedElements({
                type: 'SET_IS_LOADING_DIALOG_OPEN',
                payload: false
            })
        }
        // scenario 2 target element is a file and does not have an answer
        else if (targetElement.storageType === 'FILE' && !targetElement.answer) {

            const droppedItemsWithAnswers = droppedElements.filter(element => !!element.document)
            const shoeboxItems = droppedElements.filter(element => element.type === "SHOEBOX_ITEM")
            // answer the question with the document
            // if we are dropping only one element
            if (droppedItemsWithAnswers.length === 1) {
                dispatch(setOptimisticElementFileUploads({
                    elementId: targetElement.id,
                    fileName: droppedElements[0].document.name
                }))
                await addAnswerToElement({
                    elementId: targetElement.id,
                    documentId: droppedElements[0].document.id,
                    submit: false,
                    answerId: null,
                    isMerged: false
                })
                toast.success(`${droppedElements[0].title} ${stagedElements.stagedForCopyMove.operation === "MOVE" ? "moved" : "copied"} to ${targetElement.title}`)
                afterCopyMoveCleanup(targetElement, [targetElement])
            } else if (droppedItemsWithAnswers.length > 1) {
                dispatch(setOptimisticElementFileUploads({
                    elementId: targetElement.id,
                    fileName: 'merged.pdf'
                }))
                dispatchStagedElements({
                    type: 'SET_IS_LOADING_DIALOG_OPEN',
                    payload: true
                })
                // we need to merge the files first
                // then upload the merged file
                const files = await Promise.all(droppedElements.map(async element => {
                    const url = await getDocumentFileDownloadUrl(element.document, element.loanId);
                    return await getFileFromUrl(url, element.document.name);
                }));
                dispatchStagedElements({
                    type: 'SET_IS_LOADING_DIALOG_OPEN',
                    payload: false
                })
                onDropFiles({ targetElement, droppedFiles: files });
                // delete the shoebox items
            }
            // delete shoebox items after moving
            if (shoeboxItems.length > 0) {
                await Promise.all(shoeboxItems.map(item => deleteShoeboxItem({
                    id: item.id,
                })));
                dispatch(taskApi.util.invalidateTags([{ type: 'ConsolidatedTasksDto', id: targetElement.loanId }]))
                dispatch(getAllLoanUsersTasks(targetElement.loanId))
            }
        }
        else if (targetElement.storageType === 'FILE' && targetElement.answer) {
            dispatchStagedElements({
                type: 'SET_IS_LOADING_DIALOG_OPEN',
                payload: true
            })
            const files = await Promise.all(droppedElements.map(async element => {
                const url = await getDocumentFileDownloadUrl(element.document, element.loanId);
                return await getFileFromUrl(url, element.document.name);
            }));
            dispatchStagedElements({
                type: 'SET_IS_LOADING_DIALOG_OPEN',
                payload: false
            })
            onDropFiles({ targetElement, droppedFiles: files });
        }
    }, [addAnswerToElement, afterCopyMoveCleanup, createElements, deleteShoeboxItem, dispatch, getDocumentFileDownloadUrl, onDropFiles, stagedElements.stagedForCopyMove.operation, updateElements])

    const onStageElements = (operation: 'DELETE' | 'RENAME' | null, data: FormElementV2ResponseDtoExtended[]) => {
        dispatchStagedElements({ type: 'SET_STAGED_ELEMENTS', payload: { operation, data } })
    }

    const onRenameElement = useCallback(async (newName: string, element: FormElementV2ResponseDtoExtended) => {
        try {
            await updateElements({
                multiSelect: false,
                elements: [{
                    id: element.id,
                    loanId: element.loanId,
                    title: newName,
                }]
            });
        } catch (error) {
            console.error(error)
        }
    }, [updateElements]);

    const onDeleteElements = useCallback(async (elements: FormElementV2ResponseDtoExtended[]) => {
        try {
            await deleteElements({
                elements: elements.map(element => ({
                    id: element.id,
                    loanId: element.loanId,
                    storageType: element.storageType
                })),
                multiSelect: false
            });
        } catch (error) {
            console.error(error)
        }
    }, [deleteElements]);

    const onDownload = useCallback(async (elements: FormElementV2ResponseDtoExtended[]) => {
        const elementsWithAnswers = elements.filter(element => !!element.answer);

        // if only one element is selected download that element
        if (elementsWithAnswers.length === 1) {
            const [element] = elementsWithAnswers;
            try {
                const { downloadUrl } = await getDownloadUrl({
                    id: element.answer.document.id,
                    formElementId: element.id,
                    loanId: element.loanId
                }).unwrap();
                if (["SHAREPOINT", 'ONE_DRIVE'].includes(element.answer?.document?.providerType)) {
                    const downloadUrlString = `${window.location.origin}/api/v1/documents/${element.answer.document.id}/download?loanId=${element.loanId}`;
                    downloadFileWithExtension(downloadUrlString, element.answer.document.name);
                } else {
                    downloadFile(`${downloadUrl.toString()}?download=1`);
                }
            } catch (error) {
                console.error(error)
            }
        }
        // otherwise generate a zip with selected files
        else if (elementsWithAnswers.length > 1) {
            const [firstElement] = elementsWithAnswers;
            const elementsState = await getLoanElements({
                id: firstElement.loanId,
                view: 'CONSOLIDATED_LENDER'
            }).unwrap();
            const { totalNotAnswered, virtualElementNames, nonVirtualElementIds, uniqueElementsIds } = getSelectedElementsDetails({
                selectedElements: elements,
                allPackageElements: elementsState.list,
            });
            await generateElementsZip({
                loanId: firstElement.loanId,
                data: {
                    id: firstElement.loanId,
                    infoIdList: nonVirtualElementIds,
                    loanId: firstElement.loanId,
                    userId: null,
                    view: 'CONSOLIDATED_LENDER',
                    itemsIgnored: totalNotAnswered,
                    itemsZipped: uniqueElementsIds.length - totalNotAnswered,
                    virtualFolderNames: virtualElementNames,
                }
            })
        }
    }, [generateElementsZip, getDownloadUrl, getLoanElements])

    const onUnshare = useCallback(async (args: { users: AppUserDTO2[], elements: FormElementV2ResponseDtoExtended[] }) => {
        // loop throw multi select form elements and get all shares
        const sharedInfo = args.elements.reduce((acc, formElement) => {
            return [...acc, ...formElement.sharedInfo];
        }, [] as PackageInfoSharingResponseDto[]);

        const shareIdsToDelete = sharedInfo
            // only delete the shares that are shared with the users that we want to unshare
            .filter(share => args.users.some(user => share.sharedWithUser.id === user.id))
            .map(share => ({
                id: share.id,
                infoId: share.info.id,
                loanId: share.loanId,
                permissions: null,
                sharedByUserId: null,
                sharedWithUserId: null,
            }));

        await deleteSharedInfoElement({
            shares: shareIdsToDelete
        }).unwrap();

        toast.success('Users removed successfully')

    }, [deleteSharedInfoElement]);

    const onShare = useCallback(async (args: { users: AppUserDTO2[], elements: FormElementV2ResponseDtoExtended[], loanRoles: Pick<LoanRoleDto, 'role' | 'user'>[] }) => {
        const shares = [];

        // create a share for each user and multi select form elements
        const loanUserCanAccept = args.loanRoles.reduce((acc, loanRole) => {
            return {
                ...acc,
                [loanRole.user.id]: false//loanRole.canAcceptFiles
            }
        }, {} as Record<string, boolean>);

        const loanUserRole = args.loanRoles.reduce((acc, loanRole) => {
            return {
                ...acc,
                [loanRole.user.id]: loanRole.role
            }
        }, {} as Record<string, Role>);
        args.elements.forEach(formElement => {
            args.users.forEach(user => {
                const finalRole = loanUserRole[user.id] || user.roleDefault;
                const canAcceptFiles = (loanUserCanAccept[user.id] || isRoleAManager(finalRole) || isRoleALead(finalRole)) && !isRoleABorrower(finalRole)

                shares.push({
                    id: null,
                    infoId: formElement.id,
                    loanId: formElement.loanId,
                    permissions: [
                        'VIEW',
                        ...(canAcceptFiles
                            ? ['ACCEPT']
                            : [])
                    ],
                    sharedByUserId: loggedInUserData.user.id,
                    sharedWithUserId: user.id,
                });
            })
        });
        await createSharedInfoElement({
            shares
        }).unwrap();
        toast.success('Users assigned successfully')
    }, [createSharedInfoElement, loggedInUserData.user.id])
    const onLockedWarningDialogOpenChange = (open: boolean) => {
        if (!open) {
            dispatchStagedElements({
                type: 'SET_STAGED_FOR_ACTION',
                payload: {
                    action: null,
                    element: null
                }
            })
        }
    }
    const onStagedForMergeOpenChange = (open: boolean) => {
        if (!open) {
            dispatchStagedElements({
                type: 'SET_STAGED_FOR_MERGE',
                payload: {
                    target: {
                        type: 'ELEMENT',
                        element: null
                    },
                    elements: [],
                    files: [],
                    checkedFiles: [],
                    loading: false,
                    hasFilesNeedsConvertToPdf: false
                }
            })
        }
    };

    const onMergeFileCheckedChange = (checked: boolean, index: number) => {
        dispatchStagedElements({
            type: 'CHECK_STAGED_FILES_FOR_MERGE',
            payload: index
        })
    }

    const onSelectAllCheckedChange = (checked: boolean) => {
        dispatchStagedElements({
            type: 'SELECT_ALL_FILES_FOR_MERGE_CHECKED_CHANGE',
            payload: checked
        })
    }

    const onCopyMove = useCallback((args: OnCopyMove) => {
        if (args.type === "ELEMENTS") {
            dispatchStagedElements({
                type: 'SET_STAGED_FOR_COPY_MOVE',
                payload: {
                    type: "ELEMENTS",
                    operation: args.operation,
                    data: args.elements,
                    loanId: args.loanId
                }
            })
        } else {
            dispatchStagedElements({
                type: 'SET_STAGED_FOR_COPY_MOVE',
                payload: {
                    type: "SHOEBOX_ITEMS",
                    operation: args.operation,
                    data: args.elements,
                    loanId: args.loanId
                }
            })
        }
    }, [])


    const onRemoveAnswer = useCallback(async (elements: FormElementV2ResponseDtoExtended[]) => {
        const promises = elements.map(element => deleteAnswerFromElement({
            elementId: element.id,
            answerId: null,
            documentId: null,
            submit: null,
            isMerged: false
        }));
        await Promise.all(promises);
    }, [deleteAnswerFromElement]);

    const onConfirmCopyMove = async (target: FormElementV2ResponseDtoExtended) => {
        dispatchStagedElements({
            type: 'SET_OPERATION_IN_PROGRESS',
            payload: true
        })
        const { operation, data, type, loanId } = stagedElements.stagedForCopyMove;

        // scenario 0 we are moving and it's a shoebox item
        if (type === 'SHOEBOX_ITEMS') {
            // scenario 0.1 we are moving item to a folder
            if (target.storageType === 'FOLDER') {
                try {
                    // create elements for each shoebox item
                    const result = await createElements({
                        elements: data.map(item => ({
                            loanId: target.loanId,
                            title: item.title,
                            storageType: 'FILE',
                            parentId: target.id,
                            documentId: item.document.id
                        })),
                        multiSelect: true
                    }).unwrap();
                    // show toast message
                    // {{n}} items/{{item name}} copied to loan package successfully.
                    // if we are copying only one element
                    // we need to show the element name
                    // otherwise we need to show the number of elements
                    let message = `${data.length} ${pluralize("item", data.length)} moved to ${target.title} successfully`
                    if (data.length === 1) {
                        message = `${data[0].title} moved to ${target.title} successfully`
                    }
                    toast.success(message)
                    afterCopyMoveCleanup(target, result.list)
                } catch (error) {
                    console.error(error)
                    // display item items are copied in the background
                    toast.success(`${data.length} are been copied to ${target.title}`)
                    afterCopyMoveCleanup(target, [])
                }
            }
            // scenario 0.2 target is a file and it's approved
            else if (target.storageType === 'FILE' && target.approved) {
                dispatchStagedElements({
                    type: "SET_CONFIRM_OVERWRITE_ACCEPTED_ELEMENT",
                    payload: target
                })
            }
            // scenario 0.3 we are moving to a file
            // we are moving to an empty answer
            else if (target.storageType === 'FILE' && !target.answer) {
                // get the files for all shoebox items
                // and answer the question with the files
                const files = await Promise.all(data.map(async item => {
                    const url = await getDocumentFileDownloadUrl(item.document, loanId);
                    return await getFileFromUrl(url, item.document.name);
                }));
                onDropFiles({ targetElement: target, droppedFiles: files })
            }
            // scenario 0.4 we are moving to a file
            // and it has an answer
            else if (target.storageType === 'FILE' && target.answer) {
                // show merge dialog
                const files = await Promise.all(data.map(async item => {
                    const url = await getDocumentFileDownloadUrl(item.document, loanId);
                    return await getFileFromUrl(url, item.document.name);
                }));
                dispatchStagedElements({
                    type: 'SET_STAGED_FOR_MERGE',
                    payload: {
                        target: {
                            type: 'ELEMENT',
                            element: target
                        },
                        files,
                        elements: [],
                        checkedFiles: [],
                        loading: false,
                        hasFilesNeedsConvertToPdf: files.some(file => getFileNameExtension(file.name) !== 'pdf')
                    }
                })
            }
        }
        // scenario 1
        // if we are copying to a folder
        else if (type === "ELEMENTS" && operation === 'COPY' && target.storageType === 'FOLDER') {
            const result = await copyElements({
                sourceIds: data.map(element => element.id),
                newParentId: target.id,
                loanId: target.loanId,
            }).unwrap();
            // {{n}} items/{{item name}} copied to loan package successfully.
            // if we are copying only one element
            // we need to show the element name
            // otherwise we need to show the number of elements
            let message = `${data.length} ${pluralize("item", data.length)} copied to ${target.title} successfully`
            if (data.length === 1) {
                message = `${data[0].title} copied to ${target.title} successfully`
            }
            toast.success(message)
            afterCopyMoveCleanup(target, result.list)
        }
        // scenario 2
        // we are copying or moving and target is a file
        // and target does not have an answer
        // we need to get the source documents Files
        // and delegate the upload to onDropElements
        else if (['COPY', "MOVE"].includes(operation) && target.storageType === 'FILE') {
            // if element is accepted we need to show confirmation dialog
            if (target.approved) {
                dispatchStagedElements({
                    type: "SET_CONFIRM_OVERWRITE_ACCEPTED_ELEMENT",
                    payload: target
                })
            } else {
                // otherwise we need to upload the files
                onDropElements(target, data.map(element => ({
                    title: element.title,
                    document: element.answer.document,
                    loanId: element.loanId,
                    type: "FORM_ELEMENT",
                    id: element.id
                })))
            }
        }
        // scenario 3 moving to a folder
        else if (operation === 'MOVE' && target.storageType === 'FOLDER') {
            await updateElements({
                multiSelect: true,
                elements: data.map(element => ({
                    id: element.id,
                    loanId: element.loanId,
                    parentId: target.id
                }))
            });
            // {{n}} items/{{item name}} moved to loan package successfully.
            // if we are moving only one element
            // we need to show the element name
            // otherwise we need to show the number of elements
            let message = `${data.length} ${pluralize("item", data.length)} moved to ${target.title} successfully`
            if (data.length === 1) {
                message = `${data[0].title} moved to ${target.title} successfully`
            }
            afterCopyMoveCleanup(target, data)
            toast.success(message)
        }

    }

    const onConfirmOverwriteAcceptedElement = async () => {
        const { data, type, loanId } = stagedElements.stagedForCopyMove;
        const targetElement = stagedElements.confirmOverwriteAcceptedElement;
        dispatchStagedElements({
            type: 'SET_OPERATION_IN_PROGRESS',
            payload: true
        })
        dispatchStagedElements({
            type: 'SET_CONFIRM_OVERWRITE_ACCEPTED_ELEMENT',
            payload: null
        })
        if (type === "ELEMENTS") {
            onDropElements(targetElement,
                data.map(element => ({
                    title: element.title,
                    document: element.answer.document,
                    loanId: element.loanId,
                    type: "FORM_ELEMENT",
                    id: element.id
                })))
        } else if (type === "SHOEBOX_ITEMS") {
            const files = await Promise.all(data.map(async item => {
                const url = await getDocumentFileDownloadUrl(item.document, loanId);
                return await getFileFromUrl(url, item.document.name);
            }));
            onDropFiles({ targetElement, droppedFiles: files })
        }
    }

    const onCancelNewFolder = () => {
        dispatchStagedElements({
            type: 'SET_NEW_FOLDER_ELEMENT',
            payload: {
                target: null,
                folderName: ''
            }
        })
    }

    const onNewCreateNewFolder = (targetFolder: FormElementV2ResponseDtoExtended) => {
        dispatchStagedElements({
            type: 'SET_NEW_FOLDER_ELEMENT',
            payload: {
                target: targetFolder,
                folderName: ''
            }
        })
    }

    const onConfirmCreateNewFolder = async () => {
        const { target, folderName } = stagedElements.newFolderElement;
        try {
            dispatchStagedElements({
                type: 'SET_OPERATION_IN_PROGRESS',
                payload: true
            })
            const result = await createElements({
                elements: [{
                    loanId: target.loanId,
                    title: folderName,
                    storageType: 'FOLDER',
                    parentId: target.id
                }],
                multiSelect: false
            }).unwrap();
            // trigger the event so other components can consume it
            const createdElement = result.list[0]
            trigger('/elements/folder/created', createdElement)

            dispatchStagedElements({
                type: 'SET_NEW_FOLDER_ELEMENT',
                payload: {
                    target: null,
                    folderName: ''
                }
            })
            toast.success(`${folderName} created successfully`)
        } catch (error) {
            console.error(error)
        } finally {
            dispatchStagedElements({
                type: 'SET_OPERATION_IN_PROGRESS',
                payload: false
            })
        }
    }

    const onFolderNameChange = (folderName: string) => {
        dispatchStagedElements({
            type: 'SET_NEW_FOLDER_ELEMENT',
            payload: {
                target: stagedElements.newFolderElement.target,
                folderName
            }
        })
    }

    const onMergeConfirmClick = async (type: "WITH_EXISTING" | "REPLACE_EXISTING") => {
        const { target, checkedFiles } = stagedElements.stagedForMerge;
        let isMerged = false;
        if (target.type === 'ELEMENT') {
            const existingFiles = []
            let finalFile: File = checkedFiles[0];
            dispatchStagedElements({
                type: 'SET_MERGE_LOADING',
                payload: true
            })
            // if merge type is with existing
            // we need to get the existing file
            if (type === 'WITH_EXISTING') {
                const url = await getDocumentFileDownloadUrl(target.element.answer.document, target.element.loanId);
                const file = await getFileFromUrl(url, target.element.answer.document.name);
                existingFiles.push(file);
            }
            // otherwise we will only merge the new files
            // if we have more than one file
            // and replace the existing file
            if (checkedFiles.length > 1 || type === 'WITH_EXISTING') {
                isMerged = true;
                dispatch(setOptimisticElementFileUploads({
                    elementId: target.element.id,
                    fileName: 'merged.pdf'
                }))
                finalFile = await mergeFiles([...existingFiles, ...checkedFiles], licenseKeysData?.pdftronKey);
            }
            dispatchStagedElements({
                type: 'SET_MERGE_LOADING',
                payload: false
            })
            // close dialog we will continue upload in background
            onStagedForMergeOpenChange(false);
            // upload the file to a document
            // then answer the question with the document
            const documentId = await dispatch(uploadDocument({
                formElement: target.element,
                file: finalFile,
                loanId: target.element.loanId,
                isMerged
            }));
            await addAnswerToElement({
                elementId: target.element.id,
                documentId,
                submit: false,
                answerId: null,
                isMerged
            })
            const pluralSingular = checkedFiles.length === 1 ? "" : "s"
            if (!stagedElements.stagedForCopyMove.operation) {
                if (type === "REPLACE_EXISTING") {
                    toast.success(<UndoToast
                        onUndo={async () => handleReplaceUndo([{ element: target.element, documentId: target.element.answer.document.id, droppedFiles: [] }])}
                        message={`${target.element.title} replaced with ${checkedFiles.length} item${pluralSingular} successfully`} />)
                } else {
                    toast.success(<UndoToast
                        onUndo={async () => handleReplaceUndo([{ element: target.element, documentId: target.element.answer.document.id, droppedFiles: [] }])}
                        message={`${checkedFiles.length} item${pluralSingular} merged to ${target.element.title} successfully`} />)
                }
            } else if (type === "REPLACE_EXISTING") {
                toast.success(`${checkedFiles.length} item${pluralSingular} ${stagedElements.stagedForCopyMove.operation === "COPY" ? "copied" : "moved"} and replaced ${target.element.title} successfully`)
            } else {
                toast.success(`${checkedFiles.length} item${pluralSingular} ${stagedElements.stagedForCopyMove.operation === "COPY" ? "copied" : "moved"} and merged to ${target.element.title} successfully`)
            }
            afterCopyMoveCleanup(target.element, [])
            loanApi.util.invalidateTags([{ type: "BasicLoanDto", id: "LIST" }])
            taskApi.util.invalidateTags([{ type: 'ConsolidatedTasksDto', id: target.element.loanId }])
        }
    }

    const onCopyElementLink = useCallback(async (id: string) => {
        // get current window location
        const baseUrl = window.location.origin + window.location.pathname;
        // create a url for each form element
        const copiedUrls = `${baseUrl}?tab=${LOAN_TABS.PACKAGE}&${QUERY_PARAM_FORM_ELEMENT_ID}=${id}`;
        // copy to clipboard
        copyToClipBoard(copiedUrls);
        // show toast with text {{x}} Links/Link Copied
        toast.success('Link Copied');
    }, [copyToClipBoard])

    const onConsolidatedViewChange = useCallback((payload: FormElementContextReducerInitialState['consolidatedView']) => {
        dispatchStagedElements({
            type: 'SET_CONSOLIDATED_VIEW',
            payload
        })
    }, [])

    const onEditOneDriveFile = useCallback(async (element: FormElementV2ResponseDtoExtended, platform: "WEB" | "DESKTOP" | "WEB_ANONYMOUS"): Promise<Document> => {
        if (element) {
            dispatchStagedElements({
                type: 'SET_EDIT_ONE_DRIVE_FILE',
                payload: {
                    element,
                    isLoading: true,
                    isOpen: false
                }
            })
            try {
                const document = await getDocumentWithEditDownloadUrl({
                    id: element.answer.document.id,
                    editAnonymous: platform === "WEB_ANONYMOUS"
                }).unwrap();
                dispatchStagedElements({
                    type: 'SET_EDIT_ONE_DRIVE_FILE',
                    payload: {
                        element,
                        isLoading: false,
                        isOpen: false
                    }
                })
                updateDocument({
                    id: element.answer.document.id,
                    payload: {
                        id: element.answer.document.id,
                        name: null,
                    }
                });
                // if form element is fillable form, set in progress to true
                // and status is open
                if (element?.loanId &&
                    element?.modifiers.includes("FILLABLE_FORM")) {
                    updateElements({
                        multiSelect: false,
                        elements: [{
                            id: element.id,
                            loanId: element.loanId,
                            inProgress: true
                        }]
                    })
                }
                if (platform === "DESKTOP") {
                    window.open(document.downloadEditUrlCompletePath.toString(), '_blank', 'noreferrer noopener');
                } else if (platform === "WEB_ANONYMOUS") {
                    window.open(document.downloadEditUrl.toString(), '_blank', 'noreferrer noopener');
                } else {
                    window.open(`https://mysherpasai.sharepoint.com/_forms/default.aspx?ReturnUrl=${document.downloadEditUrl.toString()}`, '_blank', 'noreferrer noopener');
                }
                return document;
            } catch (error) {
                dispatchStagedElements({
                    type: 'SET_EDIT_ONE_DRIVE_FILE',
                    payload: {
                        element,
                        isLoading: false,
                        // only show the dialog if the platform is not anonymous
                        isOpen: platform !== "WEB_ANONYMOUS"
                    }
                })
                if (platform === "WEB_ANONYMOUS") {
                    toast.error('Unable to edit document')
                }
                return null;
            }
        } else {
            dispatchStagedElements({
                type: 'SET_EDIT_ONE_DRIVE_FILE',
                payload: {
                    element: null,
                    isLoading: false,
                    isOpen: false
                }
            });
            return null;
        }
    }, [getDocumentWithEditDownloadUrl, updateDocument, updateElements])

    const value = useMemo(() => ({
        onDropFiles,
        onShare,
        onCopyElementLink,
        onDownload,
        onRenameElement,
        onDeleteElements,
        onDropElements,
        onStageElements,
        onRemoveAnswer,
        onUnshare,
        onCopyMove,
        onSubmitElements,
        onConsolidatedViewChange,
        onEditOneDriveFile,
        stagedElements,
        isEditOneDriveFile: stagedElements.editOneDriveFile.isLoading,
        consolidatedView: stagedElements.consolidatedView,
    }), [onDropFiles, onShare, onCopyElementLink, onDownload, onRenameElement, onDeleteElements, onDropElements, onRemoveAnswer, onUnshare, onCopyMove, onSubmitElements, onConsolidatedViewChange, onEditOneDriveFile, stagedElements]);

    let checkAllChecked: CheckboxProps['checked'] = 'indeterminate';
    if (stagedElements.stagedForMerge.checkedFiles.length === stagedElements.stagedForMerge.files.length) {
        checkAllChecked = true;
    } else if (stagedElements.stagedForMerge.checkedFiles.length === 0) {
        checkAllChecked = false;
    }
    return (
        <UploadFormElementContext.Provider value={value}>
            {props.children}
            {stagedElements.editOneDriveFile.isOpen && <AddMicrosoftAccountDialog
                onDownload={(formElement) => onDownload([formElement])}
                formElement={stagedElements.editOneDriveFile.element}
                loading={stagedElements.editOneDriveFile.isLoading}
                onOpenChange={() => onEditOneDriveFile(null, null)}
                open />}
            {stagedElements.isLoadingDialogOpen && <Dialog
                open={stagedElements.isLoadingDialogOpen}>
                <Dialog.Content className='bg-transparent items-center sm:max-w-fit'>
                    <LoadingBox
                        title="Operation in progress"
                        hint="This will take a moment.." />
                </Dialog.Content>
            </Dialog>}
            <RenameAlertDialog
                name={stagedElements.data?.[0]?.title}
                open={stagedElements.operation === 'RENAME'}
                onOpenChange={() => onStageElements(null, null)}
                onRename={(newTitle: string) => onRenameElement(newTitle, stagedElements.data?.[0])}
            />
            {/* Locked warning dialog */}
            <Dialog
                onOpenChange={onLockedWarningDialogOpenChange}
                open={stagedElements.stagedForAction.action === "LOCKED_WARNING"}>
                <Dialog.Portal>
                    <Dialog.Content
                        className="p-2 pb-5 max-w-96 rounded">
                        <Dialog.Header className="p-0 flex-1 items-center justify-center">
                            <Stack className='w-full' row justify="end">
                                <Dialog.Close asChild>
                                    <Button
                                        className='rounded-full w-8 aspect-square p-0'
                                        size="sm"
                                        variant="secondary">
                                        <Icon name="Cancel" width={24} height={24} strokeWidth={1.5} />
                                    </Button>
                                </Dialog.Close>
                            </Stack>
                            <Icon name="BigExclamation" className='text-black-10' />
                            <Stack space="sm" className="pt-8 px-10 pb-6">
                                <Text
                                    center
                                    as="div" >
                                    {stagedElements.stagedForAction.element?.title} is {FormElementStatusSetting[stagedElements.stagedForAction.element?.status]?.label} and cannot be replaced/merged
                                </Text>
                            </Stack>
                        </Dialog.Header>
                    </Dialog.Content>
                </Dialog.Portal>
            </Dialog>
            {/* Merge dialog */}
            <Dialog
                open={stagedElements.stagedForMerge.target.element !== null}
                onOpenChange={onStagedForMergeOpenChange}
            >
                <Dialog.Content className='divide-y divide-gray-neutral-80 gap-0'>
                    <Dialog.Title>
                        Upload to {stagedElements.stagedForMerge.target.element?.title}
                    </Dialog.Title>
                    <Stack className='px-4 pt-2 pb-4' space="md">
                        <Text size="sm">
                            An item already exists, do you want to replace or merge uploaded item with this item{stagedElements.stagedForMerge.checkedFiles.length > 1 ? "s" : ""} ?
                        </Text>
                        <Separator />
                        <Label className='flex items-center gap-2'>
                            <Checkbox
                                onCheckedChange={(checked: boolean) => onSelectAllCheckedChange(checked)}
                                checked={checkAllChecked}
                                size="sm" />
                            Select All
                        </Label>
                        {stagedElements.stagedForMerge.hasFilesNeedsConvertToPdf && <Stack row space="sm" items="center">
                            <Icon name="InfoEmpty" className='text-gray-neutral-70' width={14} height={14} strokeWidth={1.5} />
                            <Text size="xs" variant="secondary">
                                Files will be converted to pdf if merged
                            </Text>
                        </Stack>}
                        <Stack space="md" className='h-40 overflow-y-scroll scrollbar-stable pl-4'>
                            {stagedElements.stagedForMerge.files.map((file, index) => (
                                <Stack
                                    space="sm" items="center" row key={index}>
                                    <Checkbox
                                        onCheckedChange={(checked: boolean) => onMergeFileCheckedChange(checked, index)}
                                        checked={stagedElements.stagedForMerge.checkedFiles.includes(file)}
                                        size="sm" />
                                    <FileIcon fileName={file.name} />
                                    <Text size="sm" truncate>{file.name}</Text>
                                </Stack>))}
                        </Stack>
                    </Stack>
                    <Dialog.Footer className='flex-row sm:justify-between'>
                        <Dialog.Close asChild>
                            <Button
                                disabled={stagedElements.stagedForMerge.loading}
                                variant="outline">
                                Cancel
                            </Button>
                        </Dialog.Close>
                        <Stack
                            row
                            space="md"
                            justify='end' className='flex-1'>
                            <Button
                                loading={stagedElements.stagedForMerge.loading}
                                disabled={stagedElements.stagedForMerge.checkedFiles.length === 0}
                                onClick={() => onMergeConfirmClick('REPLACE_EXISTING')}

                                variant="outline"
                                className='text-blue-100'>
                                Replace
                            </Button>
                            <Button
                                disabled={stagedElements.stagedForMerge.checkedFiles.length === 0 ||
                                    ('answer' in stagedElements.stagedForMerge.target.element && UN_MERGABLE_EXTENSIONS.includes(getFileNameExtension(stagedElements.stagedForMerge.target.element.answer.document.name))) ||
                                    stagedElements.stagedForMerge.checkedFiles.some(file => UN_MERGABLE_EXTENSIONS.includes(getFileNameExtension(file.name)))
                                }
                                loading={stagedElements.stagedForMerge.loading}
                                onClick={() => onMergeConfirmClick('WITH_EXISTING')}
                            >
                                Merge
                            </Button>
                        </Stack>
                    </Dialog.Footer>
                </Dialog.Content>
            </Dialog>
            <CopyMoveToDialog
                loanId={stagedElements.stagedForCopyMove.loanId}
                onNewFolder={onNewCreateNewFolder}
                isLoading={stagedElements.isOperationInProgress}
                onConfirm={onConfirmCopyMove}
                onCancel={() => onCopyMove({ type: null, operation: null, elements: [], loanId: null })}
                operation={stagedElements.stagedForCopyMove.operation}
                {
                ...stagedElements.stagedForCopyMove.type === "ELEMENTS"
                    ? {
                        elements: stagedElements.stagedForCopyMove.data,
                        type: stagedElements.stagedForCopyMove.type
                    }
                    : {
                        elements: stagedElements.stagedForCopyMove.data,
                        type: stagedElements.stagedForCopyMove.type
                    }
                }
            />
            <ActionAlertDialog
                open={stagedElements.confirmOverwriteAcceptedElement !== null}
                onOpenChange={() => dispatchStagedElements({
                    type: 'SET_CONFIRM_OVERWRITE_ACCEPTED_ELEMENT',
                    payload: null
                })}
                variant="danger"
                onConfirm={onConfirmOverwriteAcceptedElement}
                message={`${stagedElements.confirmOverwriteAcceptedElement?.title} is accepted. Cancel or  continue to override and copy`}
            />
            <NewFolderDialog
                isLoading={stagedElements.isOperationInProgress}
                open={stagedElements.newFolderElement.target !== null}
                onOpenChange={onCancelNewFolder}
                onFolderNameChange={onFolderNameChange}
                onConfirm={onConfirmCreateNewFolder}
            />
        </UploadFormElementContext.Provider>
    );
};


export const useUploadFormElementContext = () => {
    const context = useContext(UploadFormElementContext)
    if (context === undefined) {
        throw new Error('useUploadFormElementContext must be used within a UploadFormElementContextProvider')
    }
    return context
}
