import { j as e } from "./jsx-runtime-B6kdoens.js";
import { useState as i } from "react";
import "./Accordion-D1xtR5eX.js";
import "./Avatar-95vFNu6x.js";
import "./Badge-C0bj4DFU.js";
import "./Button-ChOUYHf0.js";
import "./DropdownMenu-BPSxuP4v.js";
import "./Icon-DEu72w0a.js";
import { I as s } from "./Input-1P9Hc4eP.js";
import "./Label-enmFkL7s.js";
import { T as l } from "./Text-DyXxCJgw.js";
import "./Tooltip-Dy7rqQaX.js";
import "./Stack-C1p7gIIp.js";
import "./Switch-CsWZTc7q.js";
import "./Toast-CNCfI-RS.js";
import "./Toggle-Cn0qt44_.js";
import { A as a } from "./AlertDialog.component-C0uV4yT1.js";
import { S as m } from "./Separator.component-BNi038DN.js";
const O = (o) => {
  const [t, r] = i("");
  return /* @__PURE__ */ e.jsxs(
    a,
    {
      onOpenChange: o.onOpenChange,
      open: o.open,
      children: [
        /* @__PURE__ */ e.jsx(a.Overlay, {}),
        /* @__PURE__ */ e.jsxs(
          a.Content,
          {
            "aria-label": "Rename",
            className: "h-auto max-w-xs rounded-md",
            children: [
              /* @__PURE__ */ e.jsx(l, { className: "px-3 pt-3", children: "Rename" }),
              /* @__PURE__ */ e.jsx(m, {}),
              /* @__PURE__ */ e.jsx("div", { className: "px-3", children: /* @__PURE__ */ e.jsx(
                s,
                {
                  autoFocus: !0,
                  "aria-label": "Rename",
                  name: "name",
                  onChange: (n) => r(n.target.value),
                  placeholder: "New name...",
                  defaultValue: o.name
                }
              ) }),
              /* @__PURE__ */ e.jsx(m, {}),
              /* @__PURE__ */ e.jsxs(a.Footer, { children: [
                /* @__PURE__ */ e.jsx(
                  a.Cancel,
                  {
                    autoFocus: !1,
                    children: "Cancel"
                  }
                ),
                /* @__PURE__ */ e.jsx(
                  a.Action,
                  {
                    onClick: () => o.onRename(t),
                    disabled: !t || o.name === t,
                    children: "Save"
                  }
                )
              ] })
            ]
          }
        )
      ]
    }
  );
};
export {
  O as R
};
