import type { FormElementV2ResponseDto } from "src/backend";

// Borrower can only view documents in status other than: OPEN, IN_PROGRESS, SUBMITTED and REJECTED

export const isFormElementLocked = (formElement: FormElementV2ResponseDto, isBorrower: boolean): boolean => {
    return (isBorrower &&
        ![
            "OPEN",
            "IN_PROGRESS",
            "SUBMITTED",
            "REJECTED"
        ].includes(formElement.status));
}