import { j as e } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as p } from "react";
import "./Accordion-D1xtR5eX.js";
import "./Avatar-95vFNu6x.js";
import "./Badge-C0bj4DFU.js";
import "./Button-ChOUYHf0.js";
import "./DropdownMenu-BPSxuP4v.js";
import "./Icon-DEu72w0a.js";
import "./Input-1P9Hc4eP.js";
import "./Label-enmFkL7s.js";
import { T as r } from "./Text-DyXxCJgw.js";
import "./Tooltip-Dy7rqQaX.js";
import "./Stack-C1p7gIIp.js";
import "./Switch-CsWZTc7q.js";
import "./Toast-CNCfI-RS.js";
import "./Toggle-Cn0qt44_.js";
import { H as s } from "./HighlightText.component-BZNRT9Y9.js";
const f = (i) => i ? i.givenName && i.familyName ? `${i.givenName} ${i.familyName}` : i.givenName ? i.givenName : i.familyName ? i.familyName : "name" in i && i.name ? i.name : "email" in i && i.email ? i.email : "emailAddress" in i && i.emailAddress ? i.emailAddress : "" : "", z = p((i, a) => {
  const { user: m, isMe: t, highlight: n = "", ...o } = i, l = t ? String(m.givenName) : f(m);
  return /* @__PURE__ */ e.jsxs(r, { as: "div", ref: a, ...o, children: [
    /* @__PURE__ */ e.jsx(s, { text: l, highlightText: n }),
    " ",
    t && /* @__PURE__ */ e.jsx(r, { as: "span", size: "inherit", variant: "secondary", children: "(Me)" })
  ] });
});
export {
  z as U
};
